<template>
  <div>
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-20"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Vehicle Information
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          @click="updateVehicle"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
            />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Update Details</span>
        </button>
      </is-authorized>
    </div>
    <div class="grid grid-cols-3 md:grid-cols-3 grid-flow-col gap-10">
      <div class="row-span-3 col-span-2">
        <div class="flex flex-wrap -my-3 -mx-5">
          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Manufacturer <span class="text-red-400">*</span></label
            >
            <input
              v-model="fleet.manufacturer"
              class="bge-input bge-input-spacing rounded"
              name="manufacturer"
              type="text"
            />
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm">Assigned To</label>
            <input
              v-if="fleet.assignment && fleet.assignment.length > 0"
              readonly
              v-model="Assigned"
              class="bge-input bge-input-spacing rounded"
              name="manufacturer"
              type="text"
            />
            <span class="mt-2" v-else>No Assignment</span>
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Model <span class="text-red-400">*</span></label
            >
            <input
              v-model="fleet.make"
              class="bge-input bge-input-spacing rounded"
              name="make"
              type="text"
            />
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm">Engine Size</label>
            <input
              v-model="fleet.engine_size"
              class="bge-input bge-input-spacing rounded"
              name="Engine Size"
              type="text"
            />
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Registration <span class="text-red-400">*</span></label
            >
            <input
              v-model="fleet.license_plate"
              class="bge-input bge-input-spacing rounded"
              name="License"
              type="text"
            />
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Chassis Number</label
            >
            <input
              v-model="fleet.chassis_no"
              class="bge-input bge-input-spacing rounded"
              name="Chassis"
              type="text"
            />
          </div>

          <div
            class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3 col-span-4"
          >
            <label class="uppercase font-semibold text-sm">Fuel Type</label>
            <input
              v-model="fleet.fuel_type"
              class="bge-input bge-input-spacing rounded"
              name="Fuel"
              type="text"
            />
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Tax Expiry <span class="text-red-400">*</span></label
            >
            <Calendar
              v-model="fleet.tax_expiry"
              dateFormat="dd/mm/yy"
              :showIcon="true"
            />
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Colour <span class="text-red-400">*</span></label
            >
            <input
              v-model="fleet.colour"
              class="bge-input bge-input-spacing rounded"
              type="text"
            />
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Tax Expiry Notification Email</label
            >
            <AutoComplete
              :multiple="true"
              v-model="fleet.tax_reminder_recipient"
              :suggestions="filteredStaffs"
              @complete="searchStaffs($event)"
              field="name"
              class="w-full"
              minLength="3"
            />
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Year Manufactured <span class="text-red-400">*</span></label
            >
            <Calendar
              v-model="fleet.manufacture_year"
              view="year"
              dateFormat="yy"
            />
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Seating Capacity</label
            >
            <input
              v-model="fleet.seat_capacity"
              class="bge-input bge-input-spacing rounded"
              name="capacity"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="row-span-3">
        <div class="flex flex-wrap -my-3 -mx-5">
          <div
            class="w-full sm:w-2/2 lg:w-2/2 flex flex-col px-5 mb-3 row-span-full"
          >
            <label class="uppercase font-semibold text-sm">Purchase Date</label>
            <Calendar
              v-model="fleet.purchase_date"
              dateFormat="dd/mm/yy"
              :showIcon="true"
            />
          </div>

          <div class="w-full sm:w-2/2 lg:w-2/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Purchase Mileage</label
            >
            <input
              v-model="fleet.purchase_mileage"
              class="bge-input bge-input-spacing rounded"
              name="Fuel"
              type="text"
            />
          </div>
          <div class="w-full sm:w-2/2 lg:w-2/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm">Note</label>
            <textarea
              v-model="fleet.notes"
              class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-3 px-4 focus:shadow w-full"
              placeholder="Note..."
              rows="10"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- Vehicle Information -->

    <!-- /Registration Details -->
  </div>
</template>

<script>
import _ from "lodash";
const Datepicker = () => import("vuejs-datepicker");
const Notification = () => import("@/components/Notification.vue");
const Spinner = () => import("@/components/Spinner.vue");
import Enumerable from "linq";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "VehicleInformation",
  props: {
    fleet: {
      type: Object,
      default: null,
    },
  },
  components: {
    Datepicker,
    Notification,
    Spinner,
    IsAuthorized,
  },
  data() {
    return {
      isBusy: false,
      Assigned: "",
      filteredStaffs: null,
    };
  },
  mounted() {
    this.Assigned = this.getAssigned(this.fleet);
  },
  methods: {
    async searchStaffs(event) {
      try {
        let query = event.query.toLowerCase();
        let results = await this.HolidayService.searchStaff(query, {
          select: "staff_id,first_name,last_name",
        });

        if (results && results.data) {
          this.filteredStaffs = results.data.map((staff) => ({
            staff_id: staff.staff_id,
            name: `${staff.first_name} ${staff.last_name}`,
          }));
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error fetching staff data:", error);
        return [];
      }
    },
    getAssigned(rowObj) {
      if (!rowObj || rowObj.assignment <= 0) {
        return "";
      }
      var assignment = Enumerable.from(rowObj.assignment)
        .where((c) => c.is_active)
        .orderBy((item) => item.requisition_enddate)
        .firstOrDefault();
      if (assignment) {
        return assignment.staff_name;
      } else {
        return "";
      }
    },
    updateVehicle: async function () {
      // Ensure vehicle has mandatory fields filled out
      let msg;
      let isAdd = false;
      if (!this.fleet.manufacturer) {
        msg = "Please enter manufacturer";
      } else if (!this.fleet.make) {
        msg = "Please enter model.";
      } else if (!this.fleet.license_plate) {
        msg = "Please enter registration.";
      } else if (!this.fleet.tax_expiry) {
        msg = "Please enter tax expiry.";
      } else if (!this.fleet.colour) {
        msg = "Please enter colour.";
      } else if (!this.fleet.manufacture_year) {
        msg = "Please enter manufacture year.";
      } else if (
        !Array.isArray(this.fleet.tax_reminder_recipient) ||
        this.fleet.tax_reminder_recipient.length === 0
      ) {
        msg = "Please add at least one tax reminder recipient";
      }
      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg,
            );
          },
          {
            position: "top-right",
          },
        );
      }

      try {
        this.isBusy = true;

        this.prepareFleetData();

        await this.FleetService.updateFleet(this.fleet);

        this.$emit("complete");

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Details Saved",
                  close: onClose,
                },
              },
              "Vehicle details saved",
            );
          },
          {
            position: "top-right",
          },
        );
        this.isBusy = false;
      } catch (err) {
        console.error(err);
        this.isBusy = false;
        let msg = !this.fleet.fleet_id
          ? "There was a problem creating the new Vehicle"
          : "There was a problem updating the Vehicle";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There was a problem updating the Vehicle",
            );
          },
          {
            position: "top-right",
          },
        );
      }
    },
    prepareFleetData() {
      // year to YYYY format
      this.fleet.manufacture_year = this.fleet.manufacture_year
        ? this.$moment(this.fleet.manufacture_year).format("YYYY")
        : null;
    },
  },
  computed: {},
};
</script>
